import React, { useEffect, useState } from 'react';
import KeyInput from './KeyInput';
import LinodeAPI, { LinodeInstance } from './linode';
import MasterInstances from './MasterInstances';
import PlayerInstances from './PlayerInstances';
import StopServersButton from './StopServersButton';
import styled from 'styled-components';
import VelocityInstances from './VelocityInstances';
import StartServersButton from './StartServersButton';
import LinodeAPIContext from './LinodeAPIContext';

interface AppProps {
  
}

const ButtonsArea = styled.div`
  padding: 20px;
`

function App(props: AppProps) {
  const [instances, setInstances] = useState<LinodeInstance[]>([]);
  const [apiKey, setApiKey] = useState<string>('');
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);
  const [buttonsStatus, setButtonsStatus] = useState<JSX.Element>(<div></div>);
  const [api, setApi] = useState<LinodeAPI>(new LinodeAPI(''));
  
  useEffect(() => {
    setNeedsRefresh(true);
  }, [apiKey]);

  useEffect(() => {
    if (needsRefresh && apiKey !== '') {
      setNeedsRefresh(false);
      (async () => {
        if (apiKey === '') return;
        setInstances(await new LinodeAPI(apiKey).listInstances());
      })();
    }
  }, [needsRefresh, apiKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNeedsRefresh(true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setApi(new LinodeAPI(apiKey));
  }, [apiKey]);

  return (
    <LinodeAPIContext.Provider value={api}>
      <h1>MultiPaper Linode Console</h1>
      <small>Version: {process.env.REACT_APP_GIT_SHA}</small>
      <br/>
      <br/>
      <KeyInput setApiKey={setApiKey} />
      { apiKey !== '' && (<div>
        <ButtonsArea>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start only master' config={{
            master: { type: 'g6-nanode-1', count: 1 }
          }}/>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start only master and studio' config={{
            master: { type: 'g6-dedicated-4', count: 1 }
          }}/>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start 25 players' config={{
            master: { type: 'g6-dedicated-4', count: 1 },
            player: { type: 'g6-dedicated-4', count: 1, serversPerInstance: 1 },
            velocity: { type: 'g6-nanode-1', count: 1 }
          }}/>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start 100 players' config={{
            master: { type: 'g6-dedicated-4', count: 1 },
            player: { type: 'g6-dedicated-8', count: 2, serversPerInstance: 2 },
            velocity: { type: 'g6-dedicated-4', count: 1 }
          }}/>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start 500 players' config={{
            master: { type: 'g6-dedicated-16', count: 1 },
            player: { type: 'g6-dedicated-16', count: 2, serversPerInstance: 3 },
            velocity: { type: 'g6-dedicated-8', count: 1 }
          }}/>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start 1000 players' config={{
            master: { type: 'g6-dedicated-16', count: 1 },
            player: { type: 'g6-dedicated-16', count: 4, serversPerInstance: 3 },
            velocity: { type: 'g6-dedicated-16', count: 1 }
          }}/>
          <StartServersButton setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Start 1500 players' config={{
            master: { type: 'g6-dedicated-16', count: 1 },
            player: { type: 'g6-dedicated-16', count: 6, serversPerInstance: 3 },
            velocity: { type: 'g6-dedicated-16', count: 2 }
          }}/>
          <br/>
          <StopServersButton apiKey={apiKey} setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Shutdown all servers' delete={false}/>
          <StopServersButton apiKey={apiKey} setNeedsRefresh={setNeedsRefresh} setStatus={setButtonsStatus} text='Delete all servers' delete={true}/>
          <br/>
          {buttonsStatus}
        </ButtonsArea>
        <button onClick={() => setNeedsRefresh(true)}>Refresh</button>
        <MasterInstances apiKey={apiKey} instances={instances.filter(instance => instance.tags.includes('master'))}/>
        <PlayerInstances apiKey={apiKey} instances={instances.filter(instance => instance.tags.includes('player'))}/>
        <VelocityInstances apiKey={apiKey} instances={instances.filter(instance => instance.tags.includes('velocity'))}/>
      </div>) }
    </LinodeAPIContext.Provider>
  );
}

export default App;
