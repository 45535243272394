import LinodeAPI, { LinodeStackScript } from "../linode";

const createVelocityScript = async (api: LinodeAPI, playerServerCountPerInstance: number): Promise<LinodeStackScript> => {
  const masterInstance = (await api.listInstances()).find(instance => instance.tags.includes('master'));
  const masterAddress = masterInstance?.ipv4[1];

  if (masterAddress === undefined) {
    throw new Error('Master instance not found');
  }

  const playerInstances = (await api.listInstances()).filter(instance => instance.tags.includes('player'));

  if (playerInstances.length === 0) {
    throw new Error('Player instance not found');
  }
  
  const servers = playerInstances.map(instance => {
    return new Array(playerServerCountPerInstance).fill(0).map((_, index) => {
      return `server-${instance.ipv4[1].split('.')[2]}-${instance.ipv4[1].split('.')[3]}-${index + 1} = "${instance.ipv4[1]}:${20000 + index + 1}"`;
    });
  }).flat();

  const firstServer = servers[0].split(' = ')[0];

  const toml = `config-version = "2.6"
bind = "0.0.0.0:$PORT"
motd = "<#09add3>Season 2.5"
show-max-players = 1000
online-mode = true
player-info-forwarding-mode = "legacy"
[servers]
${servers.join('\n')}
try = [ "${firstServer}" ]
[forced-hosts]
[advanced]
login-ratelimit = 0
`;

  const velocityStartupArguments = [
    '/usr/bin/java',
    '-Xmx$MEMORY',
    '-XX:+UseG1GC',
    '-XX:G1HeapRegionSize=4M',
    '-XX:+UnlockExperimentalVMOptions',
    '-XX:+ParallelRefProcEnabled',
    '-XX:+AlwaysPreTouch',
    '-XX:MaxInlineLevel=15',
    
    '-jar',
    'velocity.jar'
  ].join(' ');

  const script = `#!/bin/bash
apt update
apt install -y curl openjdk-17-jre-headless zip jq htop nload ufw

ufw allow ssh
ufw allow from 192.168.0.0/16
ufw --force enable

totalMemKB=$(awk '/^MemTotal:/{print $2}' /proc/meminfo)

PROJECT=velocity
MINECRAFT_VERSION=3.2.0-SNAPSHOT
BUILD_NUMBER=$(curl -s https://api.papermc.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION | jq -r '.builds' | jq -r '.[-1]')
JAR_NAME=$PROJECT-$MINECRAFT_VERSION-$BUILD_NUMBER.jar
DOWNLOAD_URL=https://api.papermc.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION/builds/$BUILD_NUMBER/downloads/$JAR_NAME

SERVICE=velocity
PORT=25565
MEMORY=$(expr $totalMemKB \\* 2 / 3)K
SERVER_JARFILE=/$SERVICE/velocity.jar

mkdir /$SERVICE
curl -Lo $SERVER_JARFILE $DOWNLOAD_URL

mkdir /$SERVICE/plugins
curl -Lo /$SERVICE/plugins/seamless-reconnect-velocity.jar https://multipaper.io/nocache/seamless-reconnect-velocity-1.0.0.jar
curl -Lo /$SERVICE/plugins/luckperms-velocity.jar https://multipaper.io/nocache/LuckPerms-Velocity-5.4.7c37629.jar

mkdir /$SERVICE/plugins/luckperms
echo "storage-method: mariadb" > /$SERVICE/plugins/luckperms/config.yml
echo "data:" >> /$SERVICE/plugins/luckperms/config.yml
echo "  address: ${masterAddress}" >> /$SERVICE/plugins/luckperms/config.yml
echo "  database: luckperms" >> /$SERVICE/plugins/luckperms/config.yml
echo "  username: root" >> /$SERVICE/plugins/luckperms/config.yml
echo "  password: 'a7Cb57_e9bwqWhch!'" >> /$SERVICE/plugins/luckperms/config.yml
echo "  pool-settings:" >> /$SERVICE/plugins/luckperms/config.yml
echo "    maximum-pool-size: 5" >> /$SERVICE/plugins/luckperms/config.yml

rm /$SERVICE/velocity.toml
${toml.replaceAll('"', '\\"').split('\n').map(line => `echo "${line}" >> /$SERVICE/velocity.toml`).join('\n')}

echo "DJGk2g7IaVg3" > /$SERVICE/forwarding.secret

UNIT_FILE=/etc/systemd/system/$SERVICE.service
echo "[Service]" > $UNIT_FILE
echo "Type=simple" >> $UNIT_FILE
echo "Restart=always" >> $UNIT_FILE
echo "RestartSec=60s" >> $UNIT_FILE
echo "ExecStart=${velocityStartupArguments}" >> $UNIT_FILE
echo "WorkingDirectory=/$SERVICE" >> $UNIT_FILE

systemctl daemon-reload
systemctl start $SERVICE
ufw allow $PORT
  `;

  const stackscripts = await api.listStackScripts('multipaper-velocity');

  if (stackscripts.length === 0) {
    return await api.createStackScript('multipaper-velocity', script);
  } else {
    return await api.updateStackScript(stackscripts[0].id, 'multipaper-velocity',script);
  }
};

export default createVelocityScript;