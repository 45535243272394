import React from 'react';
import { LinodeInstance } from './linode';
import styled from 'styled-components';
import InstanceRow from './InstanceRow';

interface InstancesListProps {
  apiKey: string;
  instances: LinodeInstance[];
  title: string;
}

const Title = styled.h2`
  text-align: center;
`

const Table = styled.div`
  div:nth-child(2n - 1) {
    background-color: #eee;
  }
  div:nth-child(2n) {
    background-color: #f7f7f7;
  }
`

function InstancesList(props: InstancesListProps) {
  return (
    <div>
      <Title>{props.title}</Title>
      <Table>
      {
        props.instances.map(instance => (
          <InstanceRow apiKey={props.apiKey} key={instance.id} instance={instance}/>
        ))
      }
      </Table>
    </div>
  );
}

export default InstancesList;
