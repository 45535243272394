import React from 'react';
import { LinodeInstance } from './linode';
import InstancesList from './InstancesList';

interface MasterInstancesProps {
  apiKey: string;
  instances: LinodeInstance[];
}

function MasterInstances(props: MasterInstancesProps) {
  return (
    <div>
      <InstancesList apiKey={props.apiKey} title="Master server" instances={props.instances} />
    </div>
  );
}

export default MasterInstances;
