import React from 'react';
import { LinodeInstance } from './linode';
import InstancesList from './InstancesList';

interface PlayerInstancesProps {
  apiKey: string;
  instances: LinodeInstance[];
}

function PlayerInstances(props: PlayerInstancesProps) {
  return (
    <div>
      <InstancesList apiKey={props.apiKey} title="Player servers" instances={props.instances} />
    </div>
  );
}

export default PlayerInstances;
