import LinodeAPI, { LinodeStackScript } from "../linode";

const createPlayerScript = async (api: LinodeAPI, playerServerCountPerInstance: number): Promise<LinodeStackScript> => {
  const masterInstance = (await api.listInstances()).find(instance => instance.tags.includes('master'));
  const masterAddress = masterInstance?.ipv4[1];

  if (masterAddress === undefined) {
    throw new Error('Master instance not found');
  }

  const playerStartupArguments = [
    '/usr/bin/java',
    '-Xmx$MEMORY',
    '-XX:+UseG1GC',
    '-XX:+ParallelRefProcEnabled',
    '-XX:MaxGCPauseMillis=200',
    '-XX:+UnlockExperimentalVMOptions',
    '-XX:+DisableExplicitGC',
    '-XX:+AlwaysPreTouch',
    '-XX:G1NewSizePercent=30',
    '-XX:G1MaxNewSizePercent=40',
    '-XX:G1HeapRegionSize=8M',
    '-XX:G1ReservePercent=20',
    '-XX:G1HeapWastePercent=5',
    '-XX:G1MixedGCCountTarget=4',
    '-XX:InitiatingHeapOccupancyPercent=15',
    '-XX:G1MixedGCLiveThresholdPercent=90',
    '-XX:G1RSetUpdatingPauseTimePercent=5',
    '-XX:SurvivorRatio=32',
    '-XX:+PerfDisableSharedMem',
    '-XX:MaxTenuringThreshold=1',
    '-Dusing.aikars.flags=https://mcflags.emc.gs',
    '-Daikars.new.flags=true',
    '-Dterminal.jline=false',
    '-Dterminal.ansi=true',
    '--add-exports=java.base/jdk.internal.ref=ALL-UNNAMED',
    '--add-exports=java.base/sun.nio.ch=ALL-UNNAMED',
    '--add-exports=jdk.unsupported/sun.misc=ALL-UNNAME',
    '--add-exports=jdk.compiler/com.sun.tools.javac.file=ALL-UNNAMED',
    '--add-opens=jdk.compiler/com.sun.tools.javac=ALL-UNNAMED',
    '--add-opens=java.base/java.lang=ALL-UNNAMED',
    '--add-opens=java.base/java.lang.reflect=ALL-UNNAMED',
    '--add-opens=java.base/java.io=ALL-UNNAMED',
    '--add-opens=java.base/java.util=ALL-UNNAMED',

    '-Dcom.mojang.eula.agree=true',
    '-DbungeecordName=$NAME',
    '-DmultipaperMasterAddress=$MASTER_ADDRESS:35353',
    '-Dproperties.allow-flight=false',
    '-Dproperties.gamemode=survival',
    '-Dproperties.view-distance=8',
    '-Dproperties.simulation-distance=2',
    '-Dproperties.online-mode=false',
    '-Dproperties.max-players=10000',
    '-Dspigot.settings.bungeecord=true',
    '-Dspigot.world-settings.default.entity-tracking-range.players=48',
    '-Dpaper.global.chunk-loading-basic.player-max-chunk-load-rate=75',
    '-Dpaper.global.chunk-loading-basic.player-max-chunk-send-rate=50',
    '-Dsr.enabled=true',
    
    '-jar',
    'multipaper.jar',
    'nogui',
    '-p',
    '$PORT'
  ].join(' ');

  const script = `#!/bin/bash
apt update
apt install -y curl openjdk-17-jre-headless zip jq htop nload ufw

ufw allow ssh
ufw allow from 192.168.0.0/16
ufw --force enable

totalMemKB=$(awk '/^MemTotal:/{print $2}' /proc/meminfo)

PROJECT=multipaper
MINECRAFT_VERSION=1.20.1
BUILD_NUMBER=$(curl -s https://api.multipaper.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION | jq -r '.builds' | jq -r '.[-1]')
JAR_NAME=$PROJECT-$MINECRAFT_VERSION-$BUILD_NUMBER.jar
DOWNLOAD_URL=https://api.multipaper.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION/builds/$BUILD_NUMBER/downloads/$JAR_NAME

for i in $(seq 1 ${playerServerCountPerInstance}); do
  SERVICE=server$i
  MASTER_ADDRESS=${masterAddress}
  PORT=$(expr 20000 + $i)
  NAME=$((hostname -I | awk '{split($2, array, "."); print array[3]; print array[4]}'; echo $i) | paste -sd "-" -)
  NAME=server-$NAME
  MEMORY=$(expr $totalMemKB \\* 2 / 3 / ${playerServerCountPerInstance})K
  SERVER_JARFILE=/$SERVICE/multipaper.jar

  mkdir /$SERVICE
  curl -Lo $SERVER_JARFILE $DOWNLOAD_URL
  rm /$SERVICE/plugins/*.jar

  VOICE_PORT=$(expr 24000 + $i)
  VOICE_HOST=$(hostname -I | awk '{print $1}')
  mkdir /$SERVICE/plugins
  mkdir /$SERVICE/plugins/voicechat
  echo "port=$VOICE_PORT" > /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "voice_host=$VOICE_HOST" >> /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "force_voice_chat=true" >> /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "max_voice_distance=25.0" >> /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "crouch_distance_multiplier=0.5" >> /$SERVICE/plugins/voicechat/voicechat-server.properties

  UNIT_FILE=/etc/systemd/system/$SERVICE.service
  echo "[Service]" > $UNIT_FILE
  echo "Type=simple" >> $UNIT_FILE
  echo "Restart=always" >> $UNIT_FILE
  echo "RestartSec=60s" >> $UNIT_FILE
  echo "ExecStart=${playerStartupArguments}" >> $UNIT_FILE
  echo "WorkingDirectory=/$SERVICE" >> $UNIT_FILE

	systemctl daemon-reload
	systemctl start $SERVICE
  ufw allow $VOICE_PORT
done
  `;

  const stackscripts = await api.listStackScripts('multipaper-player');

  if (stackscripts.length === 0) {
    return await api.createStackScript('multipaper-player', script);
  } else {
    return await api.updateStackScript(stackscripts[0].id, 'multipaper-player',script);
  }
};

export default createPlayerScript;