import React from 'react';
import { LinodeInstance } from './linode';
import InstancesList from './InstancesList';

interface VelocityInstancesProps {
  apiKey: string;
  instances: LinodeInstance[];
}

function VelocityInstances(props: VelocityInstancesProps) {
  return (
    <div>
      <InstancesList apiKey={props.apiKey} title="Velocity servers" instances={props.instances} />
    </div>
  );
}

export default VelocityInstances;
