import React from 'react';

// An input field that lets the user type in an API key, and makes the key available to the rest of the app.

interface KeyInputProps {
  setApiKey: (apiKey: string) => void;
}

function KeyInput(props: KeyInputProps) {
  const [apiKey, setApiKey] = React.useState<string>('');
  return (
    <div>
      <input
        type="text"
        value={apiKey}
        placeholder='Linode API key'
        onChange={e => setApiKey(e.target.value)}
      />
      <button onClick={() => props.setApiKey(apiKey)}>Login</button>
    </div>
  );
}

export default KeyInput;
