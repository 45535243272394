import { useContext, useEffect, useState } from 'react';
import { LinodeInstance } from './linode';
import createMasterScript from './stackscripts/master';
import createPlayerScript from './stackscripts/player';
import createVelocityScript from './stackscripts/velocity';
import LinodeAPIContext from './LinodeAPIContext';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

interface InstanceConfiguration {
  type: string;
  count: number;
}

interface PlayerInstanceConfiguration extends InstanceConfiguration {
  serversPerInstance: number;
}

interface ServerConfiguration {
  master?: InstanceConfiguration;
  player?: PlayerInstanceConfiguration;
  velocity?: InstanceConfiguration;
}

interface StartServersButtonProps {
  setNeedsRefresh: (needsRefresh: boolean) => void;
  setStatus: (status: JSX.Element) => void;
  text: string;
  config: ServerConfiguration;
}

function StartServersButton(props: StartServersButtonProps) {
  const api = useContext(LinodeAPIContext);

  const [startingServers, setStartingServers] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('...');

  useEffect(() => {
    api.getCosts().then(costs => {
      let price = 0;
      if (props.config.master !== undefined) {
        price += props.config.master.count * (costs.get(props.config.master.type) || NaN);
      }
      if (props.config.player !== undefined) {
        price += props.config.player.count * (costs.get(props.config.player.type) || NaN);
      }
      if (props.config.velocity !== undefined) {
        price += props.config.velocity.count * (costs.get(props.config.velocity.type) || NaN);
      }
      setPrice((Math.ceil(price * 100) / 100).toFixed(2)); // ceil instead of floor to avoid $0.00 prices
    });
  }, [props.config, api]);

  const setStatusMessage = (message: string) => {
    console.log(message);
    props.setStatus(<div>
      Starting servers...<br/>
      Please do not close this window until it is complete<br/>
      {message}
    </div>);
  };

  const setCompletedStatus = (message: string) => {
    console.log(message);
    props.setStatus(<div>
      {message}
    </div>);
  };

  const waitUntil = async (condition: () => Promise<boolean>) => {
    while (!await condition()) {
      await sleep(1000);
    }
  };

  const createInstances = async (type: string, count: number, tag: string, stackscriptLabel: string, creationCallback: (instance: LinodeInstance) => Promise<void> = async () => {}) => {
    await Promise.all(new Array(count).fill(null).map(() => (async () => {
      console.log(`Creating instance ${type} ${tag}`);
      const instance = await api.createInstance(`multipaper-${tag}-${Math.random().toString(36).substring(2, 15)}`, 'us-iad', type, 'linode/debian11', tag, { script_label: stackscriptLabel });
      await creationCallback(instance);
      await waitUntil(() => api.getInstance(instance.id).then(instance => instance.ipv4.length >= 2 || instance.status === 'running'));
      const instance2 = await api.getInstance(instance.id);
      console.log(`Created instance ${instance2.id} ${instance2.ipv4}`);
    })()));
  }

  const bootInstances = async (instances: LinodeInstance[]) => {
    await Promise.all(instances.map(instance => (async () => {
      console.log(`Booting instance ${instance.id}`);
      await api.bootInstance(instance.id);
      await waitUntil(() => api.getInstance(instance.id).then(instance => instance.ipv4.length >= 2 || instance.status === 'running'));
      console.log(`Booted instance ${instance.id}`);
    })()));
  }

  const createMasterVolume = async (id: number) => {
    const volumes = await api.listVolumes();
    const masterVolume = volumes.find(volume => volume.tags.includes('master'));
    if (masterVolume !== undefined) {
      await api.attachVolume(masterVolume.id, id);
    } else {
      await api.createVolume('multipaper-master-volume', 10, id, 'master');
    }
  }

  const createMasterInstances = async (config: InstanceConfiguration | undefined, existingInstances: LinodeInstance[]) => {
    if (config !== undefined) {
      const masterScript = await createMasterScript(api);
      await createInstances(config.type, config.count - existingInstances.length, 'master', masterScript.label, async instance => {
        await createMasterVolume(instance.id);
      });
      await bootInstances(existingInstances);
    }
  }

  const createPlayerInstances = async (config: PlayerInstanceConfiguration | undefined, existingInstances: LinodeInstance[]) => {
    if (config !== undefined) {
      const playerScript = await createPlayerScript(api, config.serversPerInstance);
      await createInstances(config.type, config.count - existingInstances.length, 'player', playerScript.label);
      await bootInstances(existingInstances);
    }
  }

  const createVelocityInstances = async (config: InstanceConfiguration | undefined, playerServerCountPerInstance: number, existingInstances: LinodeInstance[]) => {
    if (config !== undefined) {
      const velocityScript = await createVelocityScript(api, playerServerCountPerInstance);
      await createInstances(config.type, config.count - existingInstances.length, 'velocity', velocityScript.label);
      await bootInstances(existingInstances);
    }
  }

  const checkExistingInstancesAgainstConfig = (instances: LinodeInstance[], config?: InstanceConfiguration) => {
    instances.forEach(instance => {
      if (config === undefined) {
        throw new Error(`Server ${instance.ipv4[0] || instance.id} is not meant to exist in this configuration. Please delete all servers before using a new configuration.`);
      }
      if (instance.type !== config.type) {
        throw new Error(`Server ${instance.ipv4[0] || instance.id} is not of type ${config.type}. Please delete all servers before using a new configuration.`);
      }
      if (instance.status !== 'offline') {
        throw new Error(`Server ${instance.ipv4[0] || instance.id} is not offline. Please shut down all servers before starting them again.`);
      }
    });
  }

  const checkExistingServers = (instances: LinodeInstance[]) => {
    checkExistingInstancesAgainstConfig(instances.filter(instance => instance.tags.includes('master')), props.config.master);
    checkExistingInstancesAgainstConfig(instances.filter(instance => instance.tags.includes('player')), props.config.player);
    checkExistingInstancesAgainstConfig(instances.filter(instance => instance.tags.includes('velocity')), props.config.velocity);
  }

  const startServers = async () => {
    if (!startingServers) {
      setStartingServers(true);
      setStatusMessage('');

      const refreshInterval = setInterval(() => props.setNeedsRefresh(true), 2000);
      
      try {
        const instances = await api.listInstances();

        // Check any existing servers match the desired config
        checkExistingServers(instances);

        setStatusMessage('Starting master...');
        await createMasterInstances(props.config.master, instances.filter(instance => instance.tags.includes('master')));

        setStatusMessage('Starting player servers...');
        await createPlayerInstances(props.config.player, instances.filter(instance => instance.tags.includes('player')));

        setStatusMessage('Starting velocity servers...');
        await createVelocityInstances(props.config.velocity, props.config.player?.serversPerInstance || 1, instances.filter(instance => instance.tags.includes('velocity')));

        setStatusMessage('Updating dns record...');
        const instances2 = await api.listInstances();
        const ips = instances2.filter(instance => instance.tags.includes('velocity')).map(instance => instance.ipv4[0]);
        if (ips.length > 0) {
          await api.updateDNSRecord(ips);
        }
        
        setCompletedStatus('Start complete');
      } catch (e: any) {
        console.error(e);
        setCompletedStatus('Error while starting servers: ' + e.toString());
      }

      setStartingServers(false);
      clearInterval(refreshInterval);
      props.setNeedsRefresh(true);
    }
  };

  return (
    <div>
      <button onClick={event => startServers()} disabled={startingServers}>{ props.text } ${ price }/hr</button>
    </div>
  );
}

export default StartServersButton;
