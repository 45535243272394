import LinodeAPI, { LinodeStackScript } from "../linode";

const createMasterScript = async (api: LinodeAPI): Promise<LinodeStackScript> => {
  const studioStartupArguments = [
    '/usr/bin/java',
    '-Xmx$MEMORY',
    '-XX:+UseG1GC',
    '-XX:+ParallelRefProcEnabled',
    '-XX:MaxGCPauseMillis=200',
    '-XX:+UnlockExperimentalVMOptions',
    '-XX:+DisableExplicitGC',
    '-XX:+AlwaysPreTouch',
    '-XX:G1NewSizePercent=30',
    '-XX:G1MaxNewSizePercent=40',
    '-XX:G1HeapRegionSize=8M',
    '-XX:G1ReservePercent=20',
    '-XX:G1HeapWastePercent=5',
    '-XX:G1MixedGCCountTarget=4',
    '-XX:InitiatingHeapOccupancyPercent=15',
    '-XX:G1MixedGCLiveThresholdPercent=90',
    '-XX:G1RSetUpdatingPauseTimePercent=5',
    '-XX:SurvivorRatio=32',
    '-XX:+PerfDisableSharedMem',
    '-XX:MaxTenuringThreshold=1',
    '-Dusing.aikars.flags=https://mcflags.emc.gs',
    '-Daikars.new.flags=true',
    '-Dterminal.jline=false',
    '-Dterminal.ansi=true',
    '--add-exports=java.base/jdk.internal.ref=ALL-UNNAMED',
    '--add-exports=java.base/sun.nio.ch=ALL-UNNAMED',
    '--add-exports=jdk.unsupported/sun.misc=ALL-UNNAME',
    '--add-exports=jdk.compiler/com.sun.tools.javac.file=ALL-UNNAMED',
    '--add-opens=jdk.compiler/com.sun.tools.javac=ALL-UNNAMED',
    '--add-opens=java.base/java.lang=ALL-UNNAMED',
    '--add-opens=java.base/java.lang.reflect=ALL-UNNAMED',
    '--add-opens=java.base/java.io=ALL-UNNAMED',
    '--add-opens=java.base/java.util=ALL-UNNAMED',

    '-Dcom.mojang.eula.agree=true',
    '-DbungeecordName=$NAME',
    '-DmultipaperMasterAddress=$MASTER_ADDRESS:35353',
    '-Dproperties.view-distance=32',
    '-Dproperties.simulation-distance=2',
    '-Dproperties.online-mode=true',
    '-Dproperties.max-players=0',
    '-Dspigot.settings.bungeecord=false',
    '-Dspigot.world-settings.default.entity-tracking-range.players=512',
    '-Dpaper.global.chunk-loading-basic.player-max-chunk-load-rate=125',
    '-Dpaper.global.chunk-loading-basic.player-max-chunk-send-rate=100',
    '-Dsr.enabled=false',
    
    '-jar',
    'multipaper.jar',
    'nogui',
    '-p',
    '$PORT'
  ].join(' ');

  const script = `#!/bin/bash
apt update
apt install -y curl openjdk-17-jre-headless zip jq htop nload ufw

ufw allow ssh
ufw --force enable



## Attach volume

while [ ! -e "/dev/disk/by-id/scsi-0Linode_Volume_multipaper-master-volume" ]
do
  echo Waiting for volume to be attached
  sleep 1
done

blkid "/dev/disk/by-id/scsi-0Linode_Volume_multipaper-master-volume" || mkfs.ext4 "/dev/disk/by-id/scsi-0Linode_Volume_multipaper-master-volume"

mkdir /master
mount "/dev/disk/by-id/scsi-0Linode_Volume_multipaper-master-volume" /master
echo "/dev/disk/by-id/scsi-0Linode_Volume_multipaper-master-volume /master ext4 defaults,noatime,nofail 0 2" >> /etc/fstab



## Mariadb

apt install -y mariadb-server
sed -i 's#/var/lib/mysql#/master/mysql#g' /etc/mysql/mariadb.conf.d/50-server.cnf
sed -i 's#127.0.0.1#0.0.0.0#g' /etc/mysql/mariadb.conf.d/50-server.cnf
chown -R mysql /master/mysql
chgrp -R mysql /master/mysql
systemctl restart mariadb



## Allow connections from private network
# After we set up mariadb, cause we don't want them accessing incomplete data

ufw allow from 192.168.0.0/16



## Master

PROJECT=multipaper
MINECRAFT_VERSION=1.20.1
BUILD_NUMBER=$(curl -s https://api.multipaper.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION | jq -r '.builds' | jq -r '.[-1]')
JAR_NAME=$PROJECT-$MINECRAFT_VERSION-$BUILD_NUMBER.jar
DOWNLOAD_URL=https://api.multipaper.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION/builds/$BUILD_NUMBER/downloads/$JAR_NAME
MASTER_JAR_NAME=$(curl -s https://api.multipaper.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION/builds/$BUILD_NUMBER | jq -r '.downloads.master.name')
MASTER_DOWNLOAD_URL=https://api.multipaper.io/v2/projects/$PROJECT/versions/$MINECRAFT_VERSION/builds/$BUILD_NUMBER/downloads/$MASTER_JAR_NAME

SERVICE=master
SERVER_JARFILE=/$SERVICE/master.jar
MASTER_ADDRESS=$(hostname -I | awk '{print $2}')

mkdir /$SERVICE
curl -Lo $SERVER_JARFILE $MASTER_DOWNLOAD_URL

mkdir /$SERVICE/synced-server-files
mkdir /$SERVICE/synced-server-files/plugins
rm /$SERVICE/synced-server-files/plugins/seamless-reconnect*.jar
curl -Lo /$SERVICE/synced-server-files/plugins/seamless-reconnect.jar https://multipaper.io/nocache/seamless-reconnect-1.0.0.jar

mkdir /$SERVICE/synced-server-files/plugins/LuckPerms
echo "storage-method: mariadb" > /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "data:" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "  address: $MASTER_ADDRESS" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "  database: luckperms" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "  username: root" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "  password: 'a7Cb57_e9bwqWhch!'" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "  pool-settings:" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml
echo "    maximum-pool-size: 5" >> /$SERVICE/synced-server-files/plugins/LuckPerms/config.yml

UNIT_FILE=/etc/systemd/system/$SERVICE.service
echo "[Service]" > $UNIT_FILE
echo "Type=simple" >> $UNIT_FILE
echo "Restart=always" >> $UNIT_FILE
echo "RestartSec=60s" >> $UNIT_FILE
echo "ExecStart=/usr/bin/java -jar master.jar" >> $UNIT_FILE
echo "WorkingDirectory=/$SERVICE" >> $UNIT_FILE

systemctl daemon-reload
systemctl start $SERVICE



## Studio

totalMemKB=$(awk '/^MemTotal:/{print $2}' /proc/meminfo)

if [ $totalMemKB -gt 3000000 ]; then
  SERVICE=studio
  PORT=25565
  NAME=$((hostname -I | awk '{split($2, array, "."); print array[3]; print array[4]}'; echo 1) | paste -sd "-" -)
  NAME=studio-$NAME
  MEMORY=$(expr $totalMemKB \\* 2 / 3)K
  SERVER_JARFILE=/$SERVICE/multipaper.jar

  mkdir /$SERVICE
  curl -Lo $SERVER_JARFILE $DOWNLOAD_URL
  rm /$SERVICE/plugins/*.jar

  VOICE_PORT=24454
  VOICE_HOST=$(hostname -I | awk '{print $1}')
  mkdir /$SERVICE/plugins
  mkdir /$SERVICE/plugins/voicechat
  echo "port=$VOICE_PORT" > /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "voice_host=$VOICE_HOST" >> /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "force_voice_chat=true" >> /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "max_voice_distance=25.0" >> /$SERVICE/plugins/voicechat/voicechat-server.properties
  echo "crouch_distance_multiplier=0.5" >> /$SERVICE/plugins/voicechat/voicechat-server.properties

  UNIT_FILE=/etc/systemd/system/$SERVICE.service
  echo "[Service]" > $UNIT_FILE
  echo "Type=simple" >> $UNIT_FILE
  echo "Restart=always" >> $UNIT_FILE
  echo "RestartSec=60s" >> $UNIT_FILE
  echo "ExecStart=${studioStartupArguments}" >> $UNIT_FILE
  echo "WorkingDirectory=/$SERVICE" >> $UNIT_FILE

	systemctl daemon-reload
	systemctl start $SERVICE
  ufw allow $PORT
  ufw allow $VOICE_PORT
fi
  `;

  const stackscripts = await api.listStackScripts('multipaper-master');

  if (stackscripts.length === 0) {
    return await api.createStackScript('multipaper-master', script);
  } else {
    return await api.updateStackScript(stackscripts[0].id, 'multipaper-master',script);
  }
};

export default createMasterScript;