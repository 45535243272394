import LinodeAPI, { LinodeStackScript } from "../linode";

const runStackScriptOnBoot = async (api: LinodeAPI): Promise<LinodeStackScript> => {
  const stackscripts = await api.listStackScripts('multipaper-bootscript');
  
  if (stackscripts.length === 0) {
    const personalAccessToken = await api.createPersonalAccessToken('multipaper-bootscript', 'stackscripts:read_only');

    const script = `#!/bin/bash
#<UDF name="script_label" label="The label for the actual script to run">
# SCRIPT_LABEL=

echo "#!/bin/bash" > /startup.sh
echo "SCRIPT_LABEL=$SCRIPT_LABEL" >> /startup.sh
echo "ACCESS_TOKEN=${personalAccessToken.token}" >> /startup.sh
echo "apt update" >> /startup.sh
echo "apt install -y curl jq" >> /startup.sh
echo 'curl https://api.linode.com/v4/linode/stackscripts -H "Authorization: Bearer $ACCESS_TOKEN" -H "X-Filter: { \\"label\\": \\"$SCRIPT_LABEL\\" }" | jq -r ".data[0].script" > stackscript.sh' >> /startup.sh
echo "chmod +x /stackscript.sh" >> /startup.sh
echo "/stackscript.sh" >> /startup.sh

chmod +x /startup.sh

UNIT_FILE=/etc/systemd/system/multipaper_bootscript.service
echo "[Unit]" > $UNIT_FILE
echo "After=network.target" >> $UNIT_FILE
echo "[Service]" >> $UNIT_FILE
echo "Type=simple" >> $UNIT_FILE
echo "Restart=no" >> $UNIT_FILE
echo "ExecStart=/startup.sh" >> $UNIT_FILE
echo "WorkingDirectory=/" >> $UNIT_FILE
echo "[Install]" >> $UNIT_FILE
echo "WantedBy=multi-user.target" >> $UNIT_FILE

systemctl daemon-reload
systemctl enable multipaper_bootscript
systemctl start multipaper_bootscript
    `;

    return await api.createStackScript('multipaper-bootscript', script);
  }

  return stackscripts[0];
}

export default runStackScriptOnBoot;