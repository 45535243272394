import React from 'react';
import LinodeAPI, { LinodeInstance } from './linode';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

interface StopServersButtonProps {
  apiKey: string;
  setNeedsRefresh: (needsRefresh: boolean) => void;
  setStatus: (status: JSX.Element) => void;
  text: string;
  delete: boolean;
}

function StopServersButton(props: StopServersButtonProps) {
  const api = new LinodeAPI(props.apiKey);

  const [stoppingServers, setStoppingServers] = React.useState<boolean>(false);

  const setStatusMessage = (message: string) => {
    console.log(message);
    props.setStatus(<div>
      { props.delete ? 'Deleting' : 'Shutting down' } servers...<br/>
      Please do not close this window until it is complete<br/>
      {message}
    </div>);
  };

  const setCompletedStatus = (message: JSX.Element) => {
    console.log(message);
    props.setStatus(message);
  };

  const waitUntil = async (condition: () => Promise<boolean>) => {
    while (!await condition()) {
      await sleep(1000);
    }
  };

  const shutdownInstances = async (instances: LinodeInstance[]) => {
    await Promise.all(instances.map(instance => (async () => {
      console.log(`Shutting down instance ${instance.id}`);
      await api.shutdownInstance(instance.id);
      await waitUntil(() => api.getInstance(instance.id).then(instance => instance.status !== 'shutting_down'));
      console.log(`Shut down ${instance.id}`);
    })()));
  }

  const deleteInstances = async (instances: LinodeInstance[]) => {
    await Promise.all(instances.map(instance => (async () => {
      console.log(`Deleting instance ${instance.id}`);
      await api.deleteInstance(instance.id);
      try {
        await waitUntil(() => api.getInstance(instance.id).then(instance => instance.status !== 'deleting'));
      } catch (e) { /* probably a 404 and can't find the server */ }
      console.log(`Deleted ${instance.id}`);
    })()));
  }

  const stopServers = async () => {
    if (!stoppingServers) {
      if (!window.confirm(props.text + '\nAre you sure you want to do this?')) return;
      setStoppingServers(true);
      setStatusMessage('');

      const refreshInterval = setInterval(() => props.setNeedsRefresh(true), 2000);
      
      const instances = await new LinodeAPI(props.apiKey).listInstances();
      // Shut down the servers in order to prevent data loss
      setStatusMessage('Shutting down velocity servers...');
      await shutdownInstances(instances.filter(instance => instance.tags.includes('velocity')));
      setStatusMessage('Shutting down player servers...');
      await shutdownInstances(instances.filter(instance => instance.tags.includes('player')));
      setStatusMessage('Shutting down master server...');
      await shutdownInstances(instances.filter(instance => instance.tags.includes('master')));

      if (props.delete) {
        // Doesn't matter what order we delete them in
        setStatusMessage('Deleting servers...');
        await Promise.all([
          deleteInstances(instances.filter(instance => instance.tags.includes('velocity'))),
          deleteInstances(instances.filter(instance => instance.tags.includes('player'))),
          deleteInstances(instances.filter(instance => instance.tags.includes('master')))
        ]);
        setCompletedStatus(<div>Deletion complete</div>);
      } else {
        setCompletedStatus(<div>Shutdown complete<br/>Warning: Offline servers still cost the same amount of money as running servers. Delete them if you do not need them.</div>);
      }

      setStoppingServers(false);
      clearInterval(refreshInterval);
      props.setNeedsRefresh(true);
    }
  };

  return (
    <div>
      <button onClick={event => stopServers()} disabled={stoppingServers}>{ props.text }</button>
    </div>
  );
}

export default StopServersButton;
