import React, { useEffect } from 'react';
import LinodeAPI, { LinodeInstance } from './linode';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const waitUntil = async (condition: () => Promise<boolean>) => {
  while (!await condition()) {
    await sleep(1000);
  }
};
interface InstanceRowProps {
  apiKey: string;
  instance: LinodeInstance;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5em;
`

const Label = styled.span`
  
`

const Status = styled(Label)`

`

const Button = styled(Label)`
  cursor: pointer;
`

function InstancesList(props: InstanceRowProps) {
  const [timer, setTimer] = React.useState<string>('');
  const [deleting, setDeleting] = React.useState<boolean>(false);

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const then = new Date(props.instance.created + 'Z');
      const diff = now.getTime() - then.getTime();
      const hours = Math.floor(diff / 1000 / 60 / 60);
      const minutes = Math.floor(diff / 1000 / 60) % 60;
      const paddedMinutes = minutes.toString().padStart(2, '0');
      setTimer(`${hours}:${paddedMinutes} hrs`);
    };

    updateTimer();
    const interval = setInterval(() => {
      updateTimer();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [props.instance.created]);

  const deleteInstance = async (instance: LinodeInstance) => {
    if (!window.confirm('Are you sure you want to delete this specific instance?\nIt will not be backed up.\nPlease ensure all servers are shut down before doing this.\nOnly use this if this server is laggy and you wish to get a new replacement server')) return;
    
    setDeleting(true);
    try {
      const api = new LinodeAPI(props.apiKey);
      console.log(`Shutting down instance ${instance.id}`);
      await api.shutdownInstance(instance.id);
      await waitUntil(() => api.getInstance(instance.id).then(instance => instance.status !== 'shutting_down'));
      console.log(`Shut down ${instance.id}`);
      console.log(`Deleting instance ${instance.id}`);
      await api.deleteInstance(instance.id);
      try {
        await waitUntil(() => api.getInstance(instance.id).then(instance => instance.status !== 'deleting'));
      } catch (e) { /* probably a 404 and can't find the server */ }
      console.log(`Deleted ${instance.id}`);
    } finally {
      setDeleting(false);
    }
  }

  return (
    <Row>
      <Label>{props.instance.ipv4[0]}</Label>
      <Label>{props.instance.ipv4[1] || ''}</Label>
      <Label>{props.instance.type}</Label>
      <Status>{props.instance.status}</Status>
      <Status>{timer}</Status>
      <Button>{
        deleting ? 
        <FontAwesomeIcon icon={faSpinner} /> :
        <FontAwesomeIcon onClick={() => deleteInstance(props.instance)} icon={faTrash} />
      }</Button>
    </Row>
  );
}

export default InstancesList;
