// Create a library for accessing the Linode API. The API will be able to list currently active instances, create new instances, and delete instances. The API will be accessed using fetch

import runStackScriptOnBoot from "./stackscripts/runStackScriptOnBoot";

interface LinodeInstance {
    created: string;
    id: number;
    ipv4: string[];
    label: string;
    status: 'running' | 'offline' | 'booting' | 'rebooting' | 'shutting_down' | 'provisioning' | 'deleting' | 'migrating' | 'rebuilding' | 'cloning' | 'restoring' | 'stopped';
    tags: string[];
    type: string;
    updated: string;
}

interface LinodeImage {
    created: string;
    description: string;
    id: string;
    label: string;
    size: number;
    status: 'creating' | 'pending_upload' | 'available';
    type: 'manual' | 'automatic';
    updated: string;
}

interface LinodeDisk {
    created: string;
    filesystem: 'raw' | 'swap' | 'ext3' | 'ext4' | 'initrd';
    id: number;
    label: string;
    size: number;
    status: 'ready' | 'not ready' | 'deleting';
    updated: string;
}

interface LinodeStackScript {
    created: string;
    deployments_active: number;
    deployments_total: number;
    description: string;
    id: number;
    is_public: boolean;
    label: string;
    rev_note: string;
    script: string;
    updated: string;
    user_defined_fields: {
        description: string;
        example: string;
        name: string;
        oneof: string;
        required: boolean;
    }[];
}

interface LinodePersonalAccessToken {
    created: string;
    expiry: string | null;
    id: number;
    label: string;
    scopes: string;
    token: string;
}

interface Volume {
    created: string;
    id: string;
    label: string;
    linode_id: number;
    region: string;
    size: number;
    status: 'creating' | 'active' | 'resizing';
    tags: string[];
    updated: string;
}

class LinodeAPI {
    private readonly apiToken: string;
    private readonly apiUrl: string;
    private sshKeysCache: Promise<string[]> | null = null;
    private costsCache: Promise<Map<string, number>> | null = null;

    constructor(apiToken: string) {
        this.apiToken = apiToken;
        this.apiUrl = 'https://api.linode.com/v4';
    }

    async listInstances(): Promise<LinodeInstance[]> {
        const response = await fetch(`${this.apiUrl}/linode/instances`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'X-Filter': `{ "tags": "multipaper" }`
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list Linode instances: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data as LinodeInstance[];
    }

    async getInstance(id: number): Promise<LinodeInstance> {
        const response = await fetch(`${this.apiUrl}/linode/instances/${id}`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to get Linode instance: ${await response.text()}`);
        }

        const data = await response.json();
        return data as LinodeInstance;
    }
    
    async createInstance(label: string, region: string, type: string, image: string, tag: string, stackscriptData?: object): Promise<LinodeInstance> {
        const response = await fetch(`${this.apiUrl}/linode/instances`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                label,
                region,
                type,
                image,
                stackscript_id: (await runStackScriptOnBoot(this)).id,
                stackscript_data: stackscriptData,
                tags: [
                    'multipaper',
                    tag
                ],
                root_pass: Math.random().toString(36) + Math.random().toString(36).toUpperCase(),
                private_ip: true,
                authorized_keys: await this.getSshKeys(),
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to create Linode instance: ${await response.text()}`);
        }

        const data = await response.json();
        return data as LinodeInstance;
    }

    async deleteInstance(id: number): Promise<void> {
        const response = await fetch(`${this.apiUrl}/linode/instances/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to delete Linode instance: ${await response.text()}`);
        }
    }


    async bootInstance(id: number): Promise<void> {
        const response = await fetch(`${this.apiUrl}/linode/instances/${id}/boot`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to boot Linode instance: ${await response.text()}`);
        }
    }
    
    async shutdownInstance(id: number): Promise<void> {
        const response = await fetch(`${this.apiUrl}/linode/instances/${id}/shutdown`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to shutdown Linode instance: ${await response.text()}`);
        }
    }

    getSshKeys(): Promise<string[]> {
        if (this.sshKeysCache !== null) return this.sshKeysCache;
        return this.sshKeysCache = this.listSshKeys();
    }


    async listSshKeys(): Promise<string[]> {
        const response = await fetch(`${this.apiUrl}/profile/sshkeys`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list Linode SSH keys: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data.map((key: any) => key.ssh_key) as string[];
    }

    getCosts(): Promise<Map<string, number>> {
        if (this.costsCache !== null) return this.costsCache;
        return this.costsCache = this.listCosts();
    }

    async listCosts(): Promise<Map<string, number>> {
        const response = await fetch(`${this.apiUrl}/linode/types`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list Linode types: ${await response.text()}`);
        }

        const data = await response.json();
        const costs = new Map<string, number>();
        for (const item of data.data) {
            costs.set(item.id, item.price.hourly);
        }
        return costs;
    }

    async createImage(id: number, label: string): Promise<LinodeImage> {
        const response = await fetch(`${this.apiUrl}/images`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                disk_id: id,
                label,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to create image from Linode instance: ${await response.text()}`);
        }

        const data = await response.json();
        return data as LinodeImage;
    }

    async listImages(label: string): Promise<LinodeImage[]> {
        const response = await fetch(`${this.apiUrl}/images`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'X-Filter': `{ "label": "${label}" }`
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list Linode images: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data as LinodeImage[];
    }

    async getImage(id: string): Promise<LinodeImage> {
        const response = await fetch(`${this.apiUrl}/images/${id}`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to get info for Linode image: ${await response.text()}`);
        }

        const data = await response.json();
        return data as LinodeImage;
    }

    async listDisks(id: number): Promise<LinodeDisk[]> {
        const response = await fetch(`${this.apiUrl}/linode/instances/${id}/disks`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list disks for Linode instance: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data as LinodeDisk[];
    }

    async listStackScripts(label: string): Promise<LinodeStackScript[]> {
        const response = await fetch(`${this.apiUrl}/linode/stackscripts`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'X-Filter': `{ "label": "${label}" }`
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list stackscripts for Linode account: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data as LinodeStackScript[];
    }

    async createStackScript(label: string, script: string): Promise<LinodeStackScript> {
        const response = await fetch(`${this.apiUrl}/linode/stackscripts`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                label,
                script,
                images: [ 'any/all' ],
                is_public: false,
            }),
        });
    
        if (!response.ok) {
            throw new Error(`Failed to create stackscript for Linode account: ${await response.text()}`);
        }
    
        const data = await response.json();
        return data as LinodeStackScript;
    }

    async updateStackScript(id: number, label: string, script: string): Promise<LinodeStackScript> {
        const response = await fetch(`${this.apiUrl}/linode/stackscripts/${id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                label,
                script,
                images: [ 'any/all' ],
                is_public: false,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to update stackscript for Linode account: ${await response.text()}`);
        }

        const data = await response.json();
        return data as LinodeStackScript;
    }

    async listPersonalAccessTokens(label: string): Promise<LinodePersonalAccessToken[]> {
        const response = await fetch(`${this.apiUrl}/profile/tokens`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                '{X-Filter}': `{ "label": "${label}" }`
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list personal access tokens for Linode account: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data as LinodePersonalAccessToken[];
    }

    async createPersonalAccessToken(label: string, scopes: string): Promise<LinodePersonalAccessToken> {
        const response = await fetch(`${this.apiUrl}/profile/tokens`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                label,
                scopes,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to create personal access token for Linode account: ${await response.text()}`);
        }

        const data = await response.json();
        return data as LinodePersonalAccessToken;
    }

    async listVolumes(): Promise<Volume[]> {
        const response = await fetch(`${this.apiUrl}/volumes`, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'X-Filter': `{ "tags": "multipaper" }`
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to list volumes: ${await response.text()}`);
        }

        const data = await response.json();
        return data.data as Volume[];
    }

    async createVolume(label: string, size: number, linode_id: number, tag: string): Promise<Volume> {
        const response = await fetch(`${this.apiUrl}/volumes`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                label,
                size,
                linode_id,
                tags: [
                    'multipaper',
                    tag
                ],
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to create volume: ${await response.text()}`);
        }

        const data = await response.json();
        return data as Volume;
    }

    async attachVolume(id: string, linode_id: number): Promise<void> {
        const response = await fetch(`${this.apiUrl}/volumes/${id}/attach`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                linode_id,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to attach volume: ${await response.text()}`);
        }
    }

    async updateDNSRecord(ips: string[]) {
        return await fetch(`https://multipaper-linode-console-update-dns.multipaper.workers.dev/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ips,
            }),
        });
    }
}

export default LinodeAPI;
export type { LinodeInstance, LinodeImage, LinodeDisk, LinodeStackScript, LinodePersonalAccessToken };
